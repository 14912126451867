<template>
  <div class="services-container">
    <single-service-component v-for="(service, index) in services" :service="service"
                              :key="index"></single-service-component>
  </div>
</template>

<script>
import {services} from "@/data/services";
import SingleServiceComponent from "@/components/SingleServiceComponent";

export default {
  name: "ServicesComponent",
  components: {SingleServiceComponent},
  data() {
    return {
      services: services,
    }
  },
}
</script>

<style scoped lang="scss">

</style>