<template>
  <p class="header">
    Jesteśmy Biurem Rachunkowym z <span>Licencją Ministerstwa Finansów</span> z <span
      class="italic underline">{{ forYears }} letnim doświadczeniem</span>.
  </p>
  <div class="description">
    <img src="../assets/graphics/undraw_people_re_8spw.svg" alt="O nas">
    <p>
      Nasze biuro zostało założone w 2000 roku. Od tego czasu świadczymy usługi dla naszych Klientów. Przez ten czas
      nabraliśmy doświadczenia, dzięki czemu możemy świadczyć usługi księgowe zarówno dla małych jak i dużych
      przedsiębiorstw.
    </p>
  </div>
  <h1>Usługi</h1>
  <services-component></services-component>
  <h1>Korzyści</h1>
  <div class="services-container">

    <single-service-component :service="{
    title: 'Bezpieczeństwo',
    description: 'Jesteśmy licencjonowanym biurem rachunkowym (nr licencji Ministerstwa Finansów: 15046/99) ',
    fileName: 'undraw_certificate_re_yadi.svg'
  }"
    ></single-service-component>

    <single-service-component :service="{
    title: 'Doświadczenie',
    description: 'Dysponujemy bogatym doświadczeniem, dzięki któremu potrafimy sprostać każdemu wyzwaniu.',
    fileName: 'undraw_interaction_design_odgc.svg'
  }"
    ></single-service-component>

    <single-service-component :service="{
    title: 'Aplikacja',
    description: 'Dzięki naszej aplikacji możesz łatwo kontrolować i analizować swoje obciążenia.',
    fileName: 'undraw_progressive_app_m-9-ms.svg'
  }"
    ></single-service-component>
  </div>

</template>

<script>


import ServicesComponent from "@/components/ServicesComponent";
import SingleServiceComponent from "@/components/SingleServiceComponent";

export default {
  name: 'AboutUsView',
  components: {SingleServiceComponent, ServicesComponent},
  data() {
    return {
      forYears: (new Date().getFullYear()) - 2000, // Company since 2000
    }
  },
}
</script>
<style scoped lang="scss">
@import "@/scss/variables";

p.header {
  margin: 150px 0 230px 0;
  line-height: 1.4;
  font-size: 40px;
  font-weight: $font-weight-regular;

  @media screen and (max-width: 1050px) {
    padding: 0 60px;
    font-size: 35px;
  }

  @media screen and (max-width: 650px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 650px) {
    font-size: 25px;
  }

  span {
    font-weight: $font-weight-extra-light;

    &.italic {
      font-style: italic;
    }
  }
}

div.description {
  display: inline-flex;
  justify-content: space-between;
  gap: 50px;
  align-items: center;

  @media screen and (max-width: 730px) {
    flex-direction: column;
  }

  img {
    max-width: 35%;


    @media screen and (max-width: 730px) {
      max-width: 75%;
    }
  }

}
</style>
