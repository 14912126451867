export const services = [
  {
    'title': 'Pełna księgowość',
    'description': 'Pełna księgowość to najbardziej rozbudowana i skomplikowana forma rozliczania przychodów przedsiębiorstwa. Korzystają z niej duże i najbardziej dochodowe firmy.',
    'fileName': 'undraw_file_analysis_8k9b.svg',
    'pathName': 'full_accounting',
  },
  {
    'title': 'Uproszczona księgowość',
    'description': 'Najpopularniejsza forma księgowości. Przeznaczona dla JDG, spółek jawnych, spółek partnerskich oraz spółek cywilnych, których dochody nie przekraczają 2 mln EUR.',
    'fileName': 'undraw_my_files_swob.svg',
    'pathName': 'simplified_accounting',
  },
  {
    'title': 'Kadry i płace',
    'description': 'W ramach kadr i płac przeprowadzamy nie tylko procesy związane z zatrudnieniem, ale także kwestie finansowe - terminowe wypłaty, kontakt z urzędami itp.',
    'fileName': 'undraw_coffee_break_h3uu.svg',
    'pathName': 'human_resources',
  },
]