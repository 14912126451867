import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import VueGtag from "vue-gtag";


const options = {
  position: POSITION.TOP_CENTER,
  timeout: 5000,
};


createApp(App)
  .use(router)
  .use(Toast, options)
  .use(VueGtag, {
    disableScriptLoad: process.env.NODE_ENV === 'development',
    config: {
      id: process.env.VUE_APP_GOOGLETAG
    }
  }, router)
  .mount('#app')
