<template>
  <footer>
    <div class="container">
      <div>
        <p>Biuro Rachunkowe Justyna Krüger</p>
        <p>ul. St. Pawłowskiego 10a</p>
        <p>60-681 Poznań</p>
      </div>
      <div class="text-right">
        <p><a href="mailto:kontakt@br-kruger.pl">kontakt@br-kruger.pl</a></p>
        <p>tel. 61 653 13 18</p>
        <p><a href="/">br-kruger.pl</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

footer {
  margin: 0 auto;
  max-width: $container-width;
  padding-bottom: 50px;
  padding-top: 100px;

  @media (max-width: 570px) {
    padding-top: 30px;
  }

  div.container {
    width: 100%;
    font-weight: $font-weight-light;
    font-size: 15px;
    display: inline-flex;
    justify-content: space-between;
    padding: 20px;

    .text-right {
      text-align: right;
    }

    @media (max-width: 570px) {
      flex-direction: column;
      gap: 50px;
      align-items: center;
      text-align: center !important;
      .text-right {
        text-align: center;
      }

    }
  }

}


</style>