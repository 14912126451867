import {createRouter, createWebHashHistory} from 'vue-router'
import AboutUsView from "@/views/AboutUsView";

const routes = [
  {
    path: '/',
    name: 'about',
    component: AboutUsView
  },
  {
    path: '/uslugi',
    children: [
      {
        path: '/uslugi',
        name: 'services',
        component: () => import(/* webpackChunkName: "services" */ '../views/ServicesView.vue'),
      }, {
        path: 'uproszczona-ksiegowosc',
        name: 'simplified_accounting',
        component: () => import(/* webpackChunkName: "simplified_accounting" */ '../views/services/SimplifiedAccountingComponent'),
      },
      {
        path: 'pelna-ksiegowosc',
        name: 'full_accounting',
        component: () => import(/* webpackChunkName: "full_accounting" */ '../views/services/FullAccountingComponent'),
      },
      {
        path: 'kadry-i-place',
        name: 'human_resources',
        component: () => import(/* webpackChunkName: "human_resources" */ '../views/services/HRComponent'),
      },
    ]
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  }
]

const router = createRouter({
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
