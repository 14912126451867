<template>
  <nav-component></nav-component>
  <div class="web-content">
    <router-view/>
  </div>
  <footer-component></footer-component>
</template>

<script>
import NavComponent from "@/components/NavComponent";
import FooterComponent from "@/components/FooterComponent";

export default {
  components: {FooterComponent, NavComponent}
}
</script>

<style lang="scss">
// @ is an alias to /src
@import "scss/app";
.web-content{
  max-width: $container-width;
  margin: auto;
  padding: 20px;
  @media screen and (max-width: 768px) {
    padding-top: 160px;
  }
}
</style>
