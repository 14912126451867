<template>
  <div>
    <div class="nav-menu">
      <router-link to="/" style="border: none">
        <img class="logo" src="@/assets/logo_ver.svg" alt="Biuro Rachunkowe Logo">
      </router-link>
      <div
          class="nav-content"
          :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'"
      >
        <ul class="nav-items">
          <li>
            <router-link :to="{ name: 'about'}" @click="closeMenu">O nas</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'services'}" @click="closeMenu">Usługi</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'contact'}" @click="closeMenu">Kontakt</router-link>
          </li>
          <li>
            <a href="https://app.br-kruger.pl" target="_blank" class="button">Panel klienta</a>
          </li>
        </ul>
      </div>
      <i
          @click="showMenu()">
        <img class="kebab-menu" src="@/assets/menu.svg" alt="menu">
      </i>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavComponent",
  data() {
    return {
      showMobileMenu: false,
    };
  },
  methods: {
    closeMenu() {
      this.showMobileMenu = false;
    },
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.nav-menu {
  padding: 30px 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;

  .logo {
    max-width: 200px;
  }
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 0 0 -20px;

  li {
    margin-left: 50px;
  }

  @media screen and (max-width: 930px) {
    li {
      margin-left: 25px;
    }
  }
}

i {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav-menu {
    position: absolute;
    width: 100%;
    flex-direction: column;

    .kebab-menu {
      width: 30px;
      margin-top: 9px;
    }
  }
  .open-menu {
    opacity: 1;
    background-color: white;
    padding: 40px 20px 0 20px;
  }
  .closed-menu {
    position: fixed;
    bottom: 0;
    opacity: 0;
    height: 0;
    padding: 0;
  }
  .nav-content {
    flex-direction: column;
    z-index: 100;
    transition: all 0.2s ease-out;
  }
  .nav-items {
    flex-direction: column;

    li {
      margin-bottom: 20px;
    }

    li:last-of-type {
      margin-top: 20px;
    }

  }
  i {
    position: absolute;
    right: 20px;
    z-index: 1111;
    display: block;
    text-align: right;
    padding: 0 10px 10px 0;
  }
}
</style>