<template>
<!--  <div @click="goto(service.pathName)" :class="[service.pathName ? 'pointer' : '']">-->
  <div>
    <h3>
      {{ service.title }}
    </h3>
    <img class="underline-heading" src="@/assets/underline.png/" :alt="service.title">
    <img
        class="service"
        :src="`./img/graphics/${service.fileName}`"
        :alt="service.title"
    >
    <p>{{ service.description }}</p>
    <p v-if="service.pathName" @click="goto(service.pathName)" class="more">Więcej</p>
  </div>
</template>

<script>
import router from '@/router'

export default {
  name: "SingleServiceComponent",
  props: {
    service: {
      title: '',
      description: '',
      fileName: '',
      pathName: '',
    },
  },
  methods: {
    goto(name) {
      if (name) {
        router.push({name: name})
      }
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

div {
  margin-bottom: 30px;

  .underline-heading {
    display: block;
    width: 180px;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  img.service {
    max-height: 160px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    padding-bottom: 30px;

    @media (max-width: 900px) {
      max-height: initial;
      max-width: 50%;
    }

    @media (max-width: 450px) {
      max-height: initial;
      max-width: 75%;
    }
  }

  h3 {
    font-weight: $font-weight-light;
    display: inline-block;
  }

  a, a:hover, a:visited, a:active {
    border: none;
    color: inherit;
  }

  p.more{
    color: $accent;
    cursor: pointer;
    font-weight: $font-weight-light;
  }
}


</style>